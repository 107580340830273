import logo from './images/logo_white.svg';
import logo_min from './images/logo_min_white.svg';
import logo_min_black from './images/logo_min_black.svg';
import placeholder_token from './images/placeholder_token.png';
import icosahedron_spheres from './images/icosahedron_spheres.svg';

export const assets = {
  logo,
  logo_min,
  logo_min_black,
  placeholder_token,
  icosahedron_spheres,
};
