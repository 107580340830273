import { BrowserRouter } from 'react-router-dom';
import { PrivyProvider } from '@privy-io/react-auth';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { lazy, Suspense, useMemo } from 'react';
import { assets } from './assets/assets';

function Application() {
  const isWidget = process.env.REACT_APP_STAGE === 'widget';
  const isOnboarding = process.env.REACT_APP_STAGE === 'onboarding';
  const isTiktok = window.location.pathname.startsWith('/tiktok');

  const AppContent = useMemo(() => {
    if (isWidget) return lazy(() => import('./widget/WidgetComponent'));
    if (isOnboarding) return lazy(() => import('./onboarding/Onboarding'));
    if (isTiktok) return lazy(() => import('./tiktok/TiktokApp'));
    return lazy(() => import('./Dashboard'));
  }, [isWidget, isOnboarding, isTiktok]);

  const { theme } = useTheme();
  const { privyTheme, privyLogo } = useMemo(
    () => ({
      privyTheme: theme === 'light' ? theme : ('#1e293b' as 'dark'),
      privyLogo: theme === 'light' ? assets.logo_min_black : assets.logo_min,
    }),
    [theme]
  );

  return (
    <PrivyProvider
      appId='cm6yx6eem01d911vhc28mw2ni'
      config={{
        appearance: {
          theme: privyTheme,
          logo: privyLogo,
        },
      }}
    >
      <BrowserRouter>
        <Suspense fallback={<></>}>
          <AppContent />
        </Suspense>
      </BrowserRouter>
    </PrivyProvider>
  );
}

function AppWrapper() {
  return (
    <ThemeProvider>
      <Application />
    </ThemeProvider>
  );
}

export default AppWrapper;
