import React from 'react';
import ReactDOM from 'react-dom/client';
import Application from './Application';
import './styles/tailwind.css';
import './i18n';
import './styles/global.scss';
// import './styles/animations.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);
